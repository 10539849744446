import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { css } from '@emotion/core';
import { globalStyles } from '../@theme';
import { SEO } from '../@features';
import { Section, SectionTitle, Parragraph, Link } from '../@components/@atoms';
var _ref = {
  name: "1uygidu",
  styles: "height:100vh;display:flex;align-items:center;justify-content:center;"
};
var _ref2 = {
  name: "xi606m",
  styles: "text-align:center;"
};
export default function NotFoundPage() {
  return ___EmotionJSX(React.Fragment, null, globalStyles, ___EmotionJSX(SEO, null), ___EmotionJSX(Section, {
    css: _ref
  }, ___EmotionJSX("div", {
    css: _ref2
  }, ___EmotionJSX(SectionTitle, null, "Page not found", ' ', ___EmotionJSX("span", {
    role: "img",
    "aria-label": "Sad emoji"
  }, "\uD83D\uDE41")), ___EmotionJSX(Parragraph, null, "You tried to access that currently does not exist\uFE0F"), ___EmotionJSX(Link, {
    to: "/"
  }, "Go home"))));
}